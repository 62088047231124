.font-family-secondary {
	font-family: $font-family-secondary;
}

@each $name, $breakpoint in $grid-breakpoints {
	.list-unstyled-#{$name} {
		@include media-breakpoint-up($name) {
			@include list-unstyled();
		}
	}

	.fw-#{$name}-bold {
		@include media-breakpoint-up($name) {
			font-weight: $font-weight-bold;
		}
	}

	.text-#{$name}-black {
		@include media-breakpoint-up($name) {
			color: $black;
		}
	}
}
