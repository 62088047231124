@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Plus+Jakarta+Sans:wght@400;800&display=swap');
@import './bootstrap/index';

@import './modules/_root';
@import './modules/_nav';
@import './modules/_icon';
@import './modules/_type';
@import './modules/_button';
@import './modules/_offcanvas';
@import './modules/_link';
@import './modules/_form';
@import './modules/_footer';
@import './modules/_grid';
@import './modules/_animations';
@import "./modules/_login";
@import "./modules/_clubs";
@import "./modules/_landing";
