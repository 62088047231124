.bg-primary a:not(.nav-link):not(:hover) {
	color: $white;
}

*.rotate img, *.rotate div {
	transform: rotate(0);
	transition: transform ease-in-out 300ms;
}

*.rotate:hover {
	color: rgba($white, 0.8);
	img, div {
		transform: rotate(3deg);
		transition: transform ease-in-out 300ms;
	}
}

.backdrop {
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	transition: all 800ms ease 0s;
	backdrop-filter: blur(8px);
	// background-color: rgba($primary, 0.8);
	opacity: 0;

	@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
		backdrop-filter: blur(8px);
		-webkit-backdrop-filter: blur(8px);
		// background-color: rgba($primary, 0.4);
	}
}

.avatar-link {
	a {
		display: inline-block;
		position: relative;

		border-radius: $border-radius-lg;
		overflow: hidden;

		&:hover {
			.avatar-inner {
				/* hover effect color */
				&:before {
					height: 120px;
					width: 120px;
				}
			}

			i {
				opacity: 1;
			}

			/* hover effect blur */
			.backdrop {
				opacity: 1;
			}
		}
	}

	.avatar-inner {
		display: flex;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		align-items: center;
		justify-content: center;
		z-index: 3;

		&:before {
			content: ' ';
			position: absolute;
			z-index: 3;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			display: block;
			height: 0px;
			width: 0px;
			border-radius: 100px;

			transition: all 800ms ease 0s;

			background-color: rgba($primary, 0.8);

			@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
				background-color: rgba($primary, 0.4);
			}
		}

		i {
			opacity: 0;
			transition: all 800ms ease 0s;
			z-index: 4;
		}
	}
}
a.tt-link {
	img.position-absolute {
	  transition: opacity 0.2s ease-in-out;
	  opacity: 0;
	}
  
	&:hover {
	  img.position-absolute {
		transition: opacity 0.2s ease-in-out;
		opacity: 1;
		
	  }
	}
	.rounded-4{
		border: 2px solid rgb(255, 255, 255);
	}
  }