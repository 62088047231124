$purple: #8b65fc;
$green: #23d577;
$lime: #02f965;
$gray-fabric: #5a5d79;
$white: #fff;
$black: #000;

$primary: $gray-fabric;
$secondary: $green;

$body-color: $gray-fabric;
$headings-color: $black;

$nav-link-padding-y: 0.75rem;
$nav-link-padding-x: 1rem;
$nav-link-font-size: 1rem;
$nav-link-color: $black;
$nav-link-hover-color: $white;

$link-decoration: none;

$btn-padding-y: $nav-link-padding-y;
$btn-padding-x: $nav-link-padding-x;
$btn-border-radius: 0.5rem;
$btn-border-width: 3px;

$input-padding-y: $btn-padding-y;
$input-padding-x: $btn-padding-x;
$input-border-radius: $btn-border-radius;
$input-bg: rgba($white, 0.2);
$input-placeholder-color: $black;
$input-border-width: 0;

$offcanvas-horizontal-width: 100vw;
$offcanvas-vertical-height: 100vh;
$offcanvas-bg-color: rgba($purple, 0.4);
$offcanvas-backdrop-opacity: 0;
$offcanvas-box-shadow: none;
$offcanvas-padding-y: 2rem;
$offcanvas-padding-x: 1.5rem;

$btn-close-padding-x: $btn-padding-x;
$btn-close-padding-y: $btn-padding-y;

$spacer: 1rem;
$spacers: (
	0: 0,
	1: $spacer * 0.25,
	2: $spacer * 0.5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 2,
	6: $spacer * 3,
	7: $spacer * 4,
);

$display-font-sizes: (
	1: 5rem,
	2: 4.5rem,
	3: 4rem,
	4: 3.5rem,
	5: 3rem,
	6: 2rem,
	7: 1.5rem,
	8: 1.25rem,
);

$font-family-sans-serif: 'Plus Jakarta Sans', Arial, sans-serif;
$font-family-secondary: 'Inter', Arial, sans-serif;

$font-weight-semibold: 500;
$font-weight-bolder: 800;

$accordion-border-radius: 0;
$accordion-border-width: 0;
$accordion-button-bg: $purple;
$accordion-button-active-bg: $purple;
$accordion-button-color: $white;
$accordion-button-active-color: $white;
$accordion-icon-color: $white;
$accordion-icon-active-color: $white;

$accordion-button-icon: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.5 15V5' stroke='#{$accordion-icon-color}' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M4.5 10L14.5 10' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.5 10L14.5 10' stroke='#{$accordion-icon-active-color}' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");

$navbar-brand-margin-end: 0;
