.offcanvas-md.show {
	backdrop-filter: blur(12px);

	background-color: rgba($purple, 0.9);
	backdrop-filter: blur(12px);

	@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
		background: rgba($purple, 0.4);
		backdrop-filter: blur(12px);
		-webkit-backdrop-filter: blur(12px);
	}
}

@include media-breakpoint-down(lg) {
	.offcanvas-body {
		background: $purple;
	}
}
