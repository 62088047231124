footer {
	z-index: 2;
	font-size: 9px;
}

.footer {
	&-item {
		color: $white;

		> a:hover {
			color: $gray-400;
		}
	}
	&-item:not(:last-child)::after {
		content: "/";
		margin: 0 12px;
	}
}

@media (min-width: 768px) {
	footer {
		font-size: 16px;
	}
}
