form input,
form button {
	backdrop-filter: blur(12px);
	background: rgba($white, 0.2) !important;

	@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
		backdrop-filter: blur(12px);
		-webkit-backdrop-filter: blur(12px);
	}

	&:hover,
	&:focus,
	&:active {
		background: rgba($white, 0.4) !important;
	}
}

input[type='button'],
input[type='submit'],
button[type='button']:not(.accordion-button),
button[type='submit']:not(.accordion-button) {
	text-transform: uppercase;
}
