$icon-sizes: (
	'1': '1rem',
	'2': $nav-link-font-size,
	'3': '1.5rem',
	'4': '2rem',
	'uni': '1.25rem',
);

$icon-colors: (
	'black': $black,
	'primary': $primary,
	'white': $white,
	'grey': $gray-fabric,
	'secondary': $secondary,
);

$icon-names: [ 'tt', 'tg', 'ps', 'us', 'cg', 'dxt'];

i {
	width: 1rem;
	height: 1rem;
	display: inline-block;
	background: #000;
	mask-size: contain;
	mask-repeat: no-repeat;
	mask-position: center center;
	-webkit-mask-size: contain;
	-webkit-mask-repeat: no-repeat;
	-webkit-mask-position: center center;
	vertical-align: middle;
}

@each $name, $size in $icon-sizes {
	.icon-#{$name} {
		width: #{$size};
		height: #{$size};
	}
}

@each $name, $color in $icon-colors {
	.icon-color-#{$name} {
		background: #{$color};
	}

	a:not(.btn):hover .icon-color-#{$name} {
		background: lighten($color, 20%);
	}
}

@each $name, $color in $icon-colors {
	a:not(.btn):hover .icon-color-hover-#{$name} {
		background: #{$color};
	}
}

@each $icon in $icon-names {
	.meta-icon-#{$icon} {
		-webkit-mask-image: url('../assets/img/#{$icon}.svg');
	}
}

.active .indicator-icon .stroke {
	stroke: $white;
}

.active .indicator-icon .fill {
	fill: $white;
}

.active .indicator-icon .rect {
	stroke: $lime;
	fill: $lime;
}

.arrow-right {
	height: 18px;
	width: 100%;
	background: url(../assets/img/arrow-head.svg);
	background-position: right center;
	background-repeat: no-repeat;
	background-size: auto 100%;
	position: relative;

	&:before {
		content: ' ';
		display: block;
		width: 99.9%;
		height: 4px;
		background: $primary;
		position: absolute;
		border-radius: 3px;
		top: 50%;
		transform: translateY(-50%);
	}
}
