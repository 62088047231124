.input-search {
	height: 40px;
	font-size: 16px;
	width: 100%;
	padding: 0px 20px 0px 20px;
	border-radius: 10px;
	outline: none;
	border: solid black 2px;
	&::-webkit-search-cancel-button {
		margin-right: 25px;
	}
}
.icon-search {
	position: absolute;
	right: 13px;
	top: 8px;
	width: 25px;
}

.list-cont {
	border: 2px solid $black;
	border-radius: 10px;
	padding: 10px;
	padding-right: 0;
	height: 230px;
	overflow-y: auto;
}
.list-cont::-webkit-scrollbar {
	width: 1em;
	cursor: pointer;
}

.list-item {
	font-size: 22px;
	padding: 10px;
	height: 50px;
	font-family: 'Inter';
	font-weight: 500;
	color: $black;
	display: flex;
	align-items: center;

	img {
		width: 71px;
		margin-right: 15px;
	}
	p {
		margin-bottom: 0;
	}
	span {
		margin-left: auto;
	}
}

.list-item:hover {
	background-color: $green;
	cursor: pointer;
}

.sort-fields {
	display: flex;
	gap: 1rem;
	margin-top: 1rem;
}

.sort-fields label {
	cursor: pointer;
	padding: 0.5rem;
	color: $black;
	transition: background-color 0.3s ease;
	position: relative;
	font-size: 20px;
	font-family: 'Inter';
}

.sort-fields input:checked + label {
	color: $purple;
}

.sort-fields input[type='radio'] {
	position: absolute;
	opacity: 0;
}