.login-input:focus, input:focus{
  outline: none;
}

.selected-sorting {
  color: $purple;
}

.selected-club {
  border-color: $purple !important;
}
.login-clubs-list {
  max-height: 50%;
}
.login-continue-btn {
  width: 100%;
}
.login-sort-button {
  cursor: pointer;
  &:hover {
    color: rgba($purple, 0.65);
  }
}
@media (min-width: 768px) {
  .login-wrapper {
    max-width: 70%;
    margin: auto;
  }
  .login-welcome {
    font-weight: 700;
    font-size: 1.3rem;
  }
  .login-select-club {
    margin-top: 2rem;
  }
  .login-clubs-list {
    max-height: 30%;
  }
  .login-continue-btn {
    width: 50%;
  }
}
