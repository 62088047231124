.btn-transparent {
	background-color: rgba($white, 0.4);
	color: $black;
}

.accordion-button:disabled {
	background-color: lighten($accordion-button-bg, 20%);
}

.accordion-button {
	border-radius: $btn-border-radius !important;
}
