#home-grid {
	.illustration {
		justify-content: end;
		img {
			max-width: 85%;
			z-index: -2;
			position: relative;
		}
	}

	@include media-breakpoint-up(md) {
		.illustration-row {
			margin-top: 110px;
		}

		.illustration {
			justify-content: center;
			position: relative;
			img {
				position: absolute;
				max-width: 100%;
				z-index: initial;
			}
		}
	}


	.illustration {
		img {
			@include media-breakpoint-up(md) {
				top: -50px;
			}
			@include media-breakpoint-up(lg) {
				top: -100px;
			}
			@include media-breakpoint-up(xl) {
				top: -160px;
			}
		}
	}
}
