.join {
  color: $primary;
  &-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  &-title {
    font-weight: bold;
    margin-left: 0.5rem;
  }
  &-image {
    height: 48px;
  }
  &-content {
    > a {
      font-weight: bold;
      color: $gray-600;
    }
    > a:hover {
      color: $gray-400;
    }
  }
}
