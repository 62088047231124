.btn-tile {
	border-radius: $border-radius-lg;
	backdrop-filter: blur(12px);
	background-color: rgba($white, 0.4);

	@supports ((-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))) {
		backdrop-filter: blur(12px);
		-webkit-backdrop-filter: blur(12px);
	}
}

.navbar {
	font-family: $font-family-secondary;
	line-height: 1;
	background-color: $white;
}

.navbar-nav,
.nav-item {
	line-height: 0;
}

.nav-link {
	@extend .btn-tile;
	padding: $nav-link-padding-y * 0.85 $nav-link-padding-x * 0.85 !important;
	overflow: hidden;
	color: $black;
	transition: color 800ms ease 0s;
	display: inline-block;
	text-transform: uppercase;
	line-height: 1;
	position: relative;

	@include media-breakpoint-up(sm) {
		padding: $nav-link-padding-y $nav-link-padding-x !important;
	}

	&:before {
		content: ' ';
		position: absolute;
		z-index: -1;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: block;
		height: 0px;
		width: 0px;
		border-radius: 100px;
		background-color: $primary;
		transition: all 800ms ease 0s;
	}

	i {
		transition: background 800ms ease 0s;
	}
}

a.nav-link {
	&:hover {
		color: $white;
		i {
			background: $white !important;
		}
	}

	&:hover:before {
		height: 100px;
		width: 160px;
	}
}

.navbar-toggler {
	border: none;
	border-radius: $border-radius-lg;
	background-color: rgba($white, 0.4);
}

.social-icon-link {
	color: $black;

	&:hover {
		color: $gray-fabric;
	}
}

$pages: [ 'home', 'vision', 'product', 'team', 'login', 'clubs'];

@each $page in $pages {
	html.#{$page} {
		.nav-link.#{$page} {
			font-weight: $font-weight-bold;
		}
	}
}

/* HOME page only */
.home {
	.navbar {
		background-color: $white;
		.home {
			background: $purple;
			color: $white;
		}
		.login {
			background: $green;
			color: $primary;
			&:hover {
				color: $white
			}
		}
	}
}
